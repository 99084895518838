<template>
  <v-dialog
    v-model="dialogEditServicesIsOpen"
    scrollable
    width="90%"
    @click:outside="closeDialogEditServices()"
  >
    <v-card>
      <v-card-title>MODIFICA SERVIZI</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list two-line>
          <template>
            <v-row>
              <v-col cols="12" v-if="dialogEditServicesIsOpen">
                <TableEditServices
                  :tableEditItems="this.items"
                  @modifiedAdmin="fromChildTable"
                ></TableEditServices>
              </v-col>
            </v-row>
          </template>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="clicOnReset()"> ANNULLA </v-btn>
        <v-btn
          :disabled="servicesList.length == 0"
          color="primary"
          text
          @click="clicOnSave()"
        >
          SALVA
        </v-btn>
      </v-card-actions>
      <ModalConfirm
        @open-dialog-confirm="updateDialogConfirm"
        :dialogConfirm="openDialogConfirm"
        :text="dialogText"
        v-on:confirmed="save()"
      ></ModalConfirm>
    </v-card>
  </v-dialog>
</template>

<script>
import TableEditServices from "./core/TableEditServices.vue";
import ModalConfirm from "./ModalConfirm.vue";
export default {
  components: { TableEditServices, ModalConfirm },
  props: {
    dialogEditServicesIsOpen: {
      default: false,
      type: Boolean,
    },
    items: {
      type: Array,
      required: true,
    },
    // planId: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      planId: this.$route.params.planId,
      reset: false,
      editedTableValue: [],
      dialogText: "",
      openDialogConfirm: false,
      servicesList: [],
      itemsToEdit: [],
    };
  },
  computed: {},
  beforeMount() {
    this.itemsToEdit = [...this.items];
  },
  methods: {
    closeDialogEditServices() {
      this.$emit("open-dialog-edit-services", false);
    },
    fromChildTable(value) {
      this.editedTableValue = value;
      this.servicesList = this.editedTableValue.filter((n) => n.edit == true);
    },
    clicOnSave() {
      this.dialogText = "Vuoi confermare le modifiche inserite";
      this.updateDialogConfirm(true);
    },
    clicOnReset() {
      this.dialogText = "Vuoi chiudere le modifiche?";
      this.updateDialogConfirm(true);
    },
    updateDialogConfirm(value) {
      this.openDialogConfirm = value;
      this.$emit("cancel-edit-services");
    },
    save() {
      if (this.dialogText == "Vuoi confermare le modifiche inserite") {
        var bodyData = {
          toApi: {
            idPlan: this.planId,
            serviceInPlan: this.servicesList,
          },
          editedTableValue: this.editedTableValue,
        };

        this.$emit("confirm-edit-services", bodyData);
        this.updateDialogConfirm(false);
        this.closeDialogEditServices();
        this.servicesList = [];
      } else {
        this.updateDialogConfirm(false);
        this.closeDialogEditServices();
      }
    },
  },
};
</script>

<style scoped>
.v-list-item p {
  margin-bottom: 0px;
  margin-top: 8px;
}
td.text-start {
  font-size: 10px !important;
}
</style>
