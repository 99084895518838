<template>
  <v-dialog
    v-model="updateInfo"
    scrollable
    max-width="50%"
    @click:outside="closeDialogUpdatePlan()"
  >
    <v-card>
      <v-card-title class="primary white--text"
        >CARICAMENTO MASSIVO SERVER,STATO</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="info-failover-groups" style="height: 50vh">
        <div v-if="info.default && info.nodiRiavviati == null">
          {{ info.default }}
        </div>
        <div
          v-else-if="info.nodiRiavviati && info.nodeDisplayLabelError == null"
        >
          <h4>Nodi riavviati</h4>
          <div v-for="(item, index) in info.nodiRiavviati" :key="index">
            {{ item.nodeDisplayLabel }} - {{ item.esitoRiavvio }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <div v-if="info.nodeDisplayLabelError">
          <h4>Attenzione - Nodi trovati ma non aggiornati</h4>
          <div v-for="(item, index) in info.nodeDisplayLabelError" :key="index">
            {{ item }}
          </div>
        </div>

        <!-- Mostra l’esito del caricamento all’utente specificando numero di righe
        lette, numero di NodeInPlan aggiornati, numero di errori riscontrati,
        lista dei display label non trovati o con esito non conforme ai valori
        previsti. -->
      </v-card-text>
      <p class="notabene">
        NOTA BENE: il seguente caricamento bypassa il LOCK. A valle
        dell'inserimento, avvisare i colleghi che lavorano sul piano di
        riaggiornare eventuali pagine / schede aperte.
      </p>
      <v-divider></v-divider>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-btn left @click="exportExcel" color="secondary" dark>
          Scarica Modello
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn right color="primary" text @click="closeDialogUpdatePlan">
          Chiudi
        </v-btn>
        <v-btn right color="primary" text @click="openFileDialog">
          Inserisci
        </v-btn>
        <TextReaderUpdate
          @load="textUpdate = $event"
          :disableAlert="disableAlert"
          :update="true"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
import TextReaderUpdate from "../components/core/TextReaderUpdate.vue";
import download from "downloadjs";
export default {
  name: "ModalUpdatePlan",
  components: {
    TextReaderUpdate,
  },
  mixins: [connectionMixin],
  props: {
    updateInfo: {
      default: false,
      type: Boolean,
    },
    info: {
      type: Object, //Object
    },
  },
  data() {
    return {
      disableAlert: false,
    };
  },
  methods: {
    closeDialogUpdatePlan() {
      this.$emit("open-dialog-update-plan", false);
    },

    async openFileDialog() {
      this.$emit("open-file-dialog", false);
    },

    async exportExcel() {
      try {
        const response = await this.$http.get(
          this.$env.value("API_EXPORT_EXCEL_PROVA_IMPATTO"),
          this.optsXls
        );

        const content = response.headers["application/vnd.ms-Excel"];
        download(response.data, "Modello-Excel-Impatto" + ".csv", content);
      } catch (error) {
        console.log(error);
        alert("Qualcosa è andato storto. ");
      }
    },
  },
};
</script>

<style>
.info-failover-groups div:first-child {
  margin-top: 20px;
}

.notabene {
  padding-bottom: 30px;
  margin-left: 20px;
}
</style>
