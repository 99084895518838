<template>
  <v-dialog
    v-model="modalSelectPGIsOpen"
    scrollable
    width="500"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-card-title>
        <v-col cols="12" class="text-center"> Seleziona Priority Group </v-col>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="10" offset="1">
            <v-select
              placeholder="Seleziona Priority Group"
              name="priorityGroup"
              id="priorityGroup"
              v-model="priorityGroup"
              :items="priorityGroups"
              outlined
              dense
              hide-details
              class="limit-width"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog"> Annulla </v-btn>
        <v-btn color="primary" text @click="confirmDialog"> Conferma </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modalSelectPGIsOpen: {
      default: false,
      type: Boolean,
    },
    priorityGroups: {
      required: true,
      type: [],
    },
  },
  data() {
    return {
      priorityGroup: "",
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.$emit("modalSelectPGcancel");
      this.priorityGroup = "";
    },
    confirmDialog() {
      this.$emit("modalSelectPGconfirm", this.priorityGroup);
      this.priorityGroup = "";
    },
  },
};
</script>

<style scoped>
.v-list-item p {
  margin-bottom: 0px;
  margin-top: 8px;
}
.text-center {
  text-align: center;
}
</style>
